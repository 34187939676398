<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <el-row>
            <el-col :span="24">
                <el-form
                    ref="addForm"
                    size="small"
                >
                    <el-tabs
                        tab-position="left"
                        v-model="tabName"
                    >
                        <el-tab-pane
                            v-for="item in shopPaymentConfigList"
                            :key="item.id"
                            :label="item.paymentTypeName"
                            :name="item.paymentTypeName"
                        >
                            <el-col
                                :span="12"
                                v-if="item.id === 1"
                            >
                                <el-form-item
                                    prop="bankAccountName"
                                    label="银行开户名"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.bankAccountName"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="bankAccountNo"
                                    label="银行账号"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.bankAccountNo"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="depositBank"
                                    label="开户银行"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.depositBank"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="branchBankName"
                                    label="支行名称"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.branchBankName"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col
                                :span="12"
                                v-if="item.id === 2"
                            >
                                <el-form-item
                                    prop="wxaccountAppid"
                                    label="公众号appid"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.wxaccountAppid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="wxaccountMchid"
                                    label="支付商户ID"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.wxaccountMchid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="wxaccountMchkey"
                                    label="支付商户key"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.wxaccountMchkey"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="subWxaccountAppid"
                                    label="子公众号appid"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.subWxaccountAppid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="subWxaccountMchid"
                                    label="子支付商户ID"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.subWxaccountMchid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageRate"
                                    label="手续费(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageCostRate"
                                    label="手续费成本(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageCostRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="exchangeRate"
                                    label="汇率"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="6"
                                        v-model.number="item.paymentConfig.exchangeRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label="渠道商手续费"
                                    label-width="10em"
                                >
                                    <div class="ma-b">
                                        <el-button @click="onAdd(item)">
                                            新增
                                        </el-button>
                                    </div>
                                    <el-table
                                        stripe
                                        border
                                        size="small"
                                        :data="item.paymentConfig.externalPoundageAmountList"
                                    >
                                        <el-table-column label="付款渠道">
                                            <template slot-scope="scope">
                                                <el-input
                                                    v-model="scope.row.paymentChannel"
                                                    :maxlength="20"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="手续费比例(%)">
                                            <template slot-scope="scope">
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="scope.row.poundageRate"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            label="操作"
                                            width="60"
                                        >
                                            <template slot-scope="scope">
                                                <el-button
                                                    size="small"
                                                    type="text"
                                                    @click="onDelete(scope.$index, item.paymentConfig.externalPoundageAmountList)"
                                                >
                                                    <span>删除</span>
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-form-item>
                                <el-form-item
                                    prop="isCustomsClearance"
                                    label="是否海关报送"
                                    label-width="10em"
                                >
                                    <el-switch
                                        v-model="item.paymentConfig.isCustomsClearance"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="customsPlace"
                                    label="海关编号"
                                    label-width="10em"
                                    v-if="item.paymentConfig.isCustomsClearance"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.customsPlace"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="merchantCustomsName"
                                    label="商户海关备案名称"
                                    label-width="10em"
                                    v-if="item.paymentConfig.isCustomsClearance"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.merchantCustomsName"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="merchantCustomsCode"
                                    label="商户海关备案编号"
                                    label-width="10em"
                                    v-if="item.paymentConfig.isCustomsClearance"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.merchantCustomsCode"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col
                                :span="12"
                                v-if="item.id === 4"
                            >
                                <el-form-item
                                    prop="unionpayMid"
                                    label="银联支付商户号"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.unionpayMid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="unionpayTid"
                                    label="银联支付终端号"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.unionpayTid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="unionpayInstmid"
                                    label="银联支付机构商户号"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.unionpayInstmid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="unionpayMsgsrc"
                                    label="银联支付消息来源"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.unionpayMsgsrc"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="unionpayMsgsrcid"
                                    label="银联支付来源编号"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.unionpayMsgsrcid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="unionpayKey"
                                    label="银联支付密钥"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.unionpayKey"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageRate"
                                    label="手续费(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageCostRate"
                                    label="手续费成本(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageCostRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="exchangeRate"
                                    label="汇率"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="6"
                                        v-model.number="item.paymentConfig.exchangeRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label="渠道商手续费"
                                    label-width="10em"
                                >
                                    <div class="ma-b">
                                        <el-button @click="onAdd(item)">
                                            新增
                                        </el-button>
                                    </div>
                                    <el-table
                                        stripe
                                        border
                                        size="small"
                                        :data="item.paymentConfig.externalPoundageAmountList"
                                    >
                                        <el-table-column label="付款渠道">
                                            <template slot-scope="scope">
                                                <el-input
                                                    v-model="scope.row.paymentChannel"
                                                    :maxlength="20"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="手续费比例(%)">
                                            <template slot-scope="scope">
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="scope.row.poundageRate"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            label="操作"
                                            width="60"
                                        >
                                            <template slot-scope="scope">
                                                <el-button
                                                    size="small"
                                                    type="text"
                                                    @click="onDelete(scope.$index, item.paymentConfig.externalPoundageAmountList)"
                                                >
                                                    <span>删除</span>
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-form-item>
                            </el-col>
                            <el-col
                                :span="12"
                                v-if="item.id === 8"
                            >
                                <el-form-item
                                    prop="allinpayCusid"
                                    label="通联支付商户号"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.allinpayCusid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="allinpayAppid"
                                    label="通联支付APPID"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.allinpayAppid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="allinpayKey"
                                    label="通联支付密钥"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.allinpayKey"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageRate"
                                    label="手续费(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageCostRate"
                                    label="手续费成本(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageCostRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="exchangeRate"
                                    label="汇率"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="6"
                                        v-model.number="item.paymentConfig.exchangeRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label="渠道商手续费"
                                    label-width="10em"
                                >
                                    <div class="ma-b">
                                        <el-button @click="onAdd(item)">
                                            新增
                                        </el-button>
                                    </div>
                                    <el-table
                                        stripe
                                        border
                                        size="small"
                                        :data="item.paymentConfig.externalPoundageAmountList"
                                    >
                                        <el-table-column label="付款渠道">
                                            <template slot-scope="scope">
                                                <el-input
                                                    v-model="scope.row.paymentChannel"
                                                    :maxlength="20"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="手续费比例(%)">
                                            <template slot-scope="scope">
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="scope.row.poundageRate"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            label="操作"
                                            width="60"
                                        >
                                            <template slot-scope="scope">
                                                <el-button
                                                    size="small"
                                                    type="text"
                                                    @click="onDelete(scope.$index, item.paymentConfig.externalPoundageAmountList)"
                                                >
                                                    <span>删除</span>
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-form-item>
                            </el-col>
                            <el-col
                                :span="12"
                                v-if="item.id === 16"
                            >
                                <el-form-item
                                    prop="alphapayPartnercode"
                                    label="alphapay Partnercode"
                                    label-width="12em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.alphapayPartnercode"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="alphapayCredentialcode"
                                    label="alphapay Credentialcode"
                                    label-width="12em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.alphapayCredentialcode"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="currency"
                                    label="币种"
                                    label-width="12em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.currency"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="apiUrl"
                                    label="请求地址"
                                    label-width="12em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.apiUrl"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageRate"
                                    label="手续费(%)"
                                    label-width="12em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageCostRate"
                                    label="手续费成本(%)"
                                    label-width="12em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageCostRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="exchangeRate"
                                    label="汇率"
                                    label-width="12em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="6"
                                        v-model.number="item.paymentConfig.exchangeRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label="渠道商手续费"
                                    label-width="12em"
                                >
                                    <div class="ma-b">
                                        <el-button @click="onAdd(item)">
                                            新增
                                        </el-button>
                                    </div>
                                    <el-table
                                        stripe
                                        border
                                        size="small"
                                        :data="item.paymentConfig.externalPoundageAmountList"
                                    >
                                        <el-table-column label="付款渠道">
                                            <template slot-scope="scope">
                                                <el-input
                                                    v-model="scope.row.paymentChannel"
                                                    :maxlength="20"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="手续费比例(%)">
                                            <template slot-scope="scope">
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="scope.row.poundageRate"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            label="操作"
                                            width="60"
                                        >
                                            <template slot-scope="scope">
                                                <el-button
                                                    size="small"
                                                    type="text"
                                                    @click="onDelete(scope.$index, item.paymentConfig.externalPoundageAmountList)"
                                                >
                                                    <span>删除</span>
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-form-item>
                            </el-col>
                            <el-col
                                :span="12"
                                v-if="item.id === 32"
                            >
                                <el-form-item
                                    prop="alipayAppid"
                                    label="支付宝商户appid"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.alipayAppid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="alipayPublickey"
                                    label="支付宝公钥"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.alipayPublickey"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="alipayPrivatekey"
                                    label="支付宝私钥"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.alipayPrivatekey"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageRate"
                                    label="手续费(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageCostRate"
                                    label="手续费成本(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageCostRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="exchangeRate"
                                    label="汇率"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="6"
                                        v-model.number="item.paymentConfig.exchangeRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label="渠道商手续费"
                                    label-width="10em"
                                >
                                    <div class="ma-b">
                                        <el-button @click="onAdd(item)">
                                            新增
                                        </el-button>
                                    </div>
                                    <el-table
                                        stripe
                                        border
                                        size="small"
                                        :data="item.paymentConfig.externalPoundageAmountList"
                                    >
                                        <el-table-column label="付款渠道">
                                            <template slot-scope="scope">
                                                <el-input
                                                    v-model="scope.row.paymentChannel"
                                                    :maxlength="20"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="手续费比例(%)">
                                            <template slot-scope="scope">
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="scope.row.poundageRate"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            label="操作"
                                            width="60"
                                        >
                                            <template slot-scope="scope">
                                                <el-button
                                                    size="small"
                                                    type="text"
                                                    @click="onDelete(scope.$index, item.paymentConfig.externalPoundageAmountList)"
                                                >
                                                    <span>删除</span>
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-form-item>
                                <el-form-item
                                    prop="isCustomsClearance"
                                    label="是否海关报送"
                                    label-width="10em"
                                >
                                    <el-switch
                                        v-model="item.paymentConfig.isCustomsClearance"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="customsPlace"
                                    label="海关编号"
                                    label-width="10em"
                                    v-if="item.paymentConfig.isCustomsClearance"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.customsPlace"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="merchantCustomsName"
                                    label="商户海关备案名称"
                                    label-width="10em"
                                    v-if="item.paymentConfig.isCustomsClearance"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.merchantCustomsName"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="merchantCustomsCode"
                                    label="商户海关备案编号"
                                    label-width="10em"
                                    v-if="item.paymentConfig.isCustomsClearance"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.merchantCustomsCode"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="partner"
                                    label="合作者身份ID"
                                    label-width="10em"
                                    v-if="item.paymentConfig.isCustomsClearance"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.partner"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="partnerKey"
                                    label="合作者密钥"
                                    label-width="10em"
                                    v-if="item.paymentConfig.isCustomsClearance"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.partnerKey"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col
                                :span="12"
                                v-if="item.id === 64"
                            >
                                <el-form-item
                                    prop="secretKey"
                                    label="secretKey"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.secretKey"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="publishableKey"
                                    label="publishableKey"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.publishableKey"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="currency"
                                    label="币种"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.currency"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="domainName"
                                    label="域名"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.domainName"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageRate"
                                    label="手续费(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageCostRate"
                                    label="手续费成本(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageCostRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="exchangeRate"
                                    label="汇率"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="6"
                                        v-model.number="item.paymentConfig.exchangeRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label="渠道商手续费"
                                    label-width="10em"
                                >
                                    <div class="ma-b">
                                        <el-button @click="onAdd(item)">
                                            新增
                                        </el-button>
                                    </div>
                                    <el-table
                                        stripe
                                        border
                                        size="small"
                                        :data="item.paymentConfig.externalPoundageAmountList"
                                    >
                                        <el-table-column label="付款渠道">
                                            <template slot-scope="scope">
                                                <el-input
                                                    v-model="scope.row.paymentChannel"
                                                    :maxlength="20"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="手续费比例(%)">
                                            <template slot-scope="scope">
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="scope.row.poundageRate"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            label="操作"
                                            width="60"
                                        >
                                            <template slot-scope="scope">
                                                <el-button
                                                    size="small"
                                                    type="text"
                                                    @click="onDelete(scope.$index, item.paymentConfig.externalPoundageAmountList)"
                                                >
                                                    <span>删除</span>
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-form-item>
                            </el-col>
                            <el-col
                                :span="12"
                                v-if="item.id === 128"
                            >
                                <el-form-item
                                    prop="platmerid"
                                    label="商户号"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.platmerid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="platmerpwd"
                                    label="私钥密码"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.platmerpwd"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="appId"
                                    label="微信公众号AppId"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.appId"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageRate"
                                    label="手续费(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageCostRate"
                                    label="手续费成本(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageCostRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="exchangeRate"
                                    label="汇率"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="6"
                                        v-model.number="item.paymentConfig.exchangeRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label="渠道商手续费"
                                    label-width="10em"
                                >
                                    <div class="ma-b">
                                        <el-button @click="onAdd(item)">
                                            新增
                                        </el-button>
                                    </div>
                                    <el-table
                                        stripe
                                        border
                                        size="small"
                                        :data="item.paymentConfig.externalPoundageAmountList"
                                    >
                                        <el-table-column label="付款渠道">
                                            <template slot-scope="scope">
                                                <el-input
                                                    v-model="scope.row.paymentChannel"
                                                    :maxlength="20"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="手续费比例(%)">
                                            <template slot-scope="scope">
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="scope.row.poundageRate"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            label="操作"
                                            width="60"
                                        >
                                            <template slot-scope="scope">
                                                <el-button
                                                    size="small"
                                                    type="text"
                                                    @click="onDelete(scope.$index, item.paymentConfig.externalPoundageAmountList)"
                                                >
                                                    <span>删除</span>
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-form-item>
                            </el-col>
                            <el-col
                                :span="12"
                                v-if="item.id === 256"
                            >
                                <el-form-item
                                    prop="token"
                                    label="token"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.token"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="currency"
                                    label="币种"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.currency"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageRate"
                                    label="手续费(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageCostRate"
                                    label="手续费成本(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageCostRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="exchangeRate"
                                    label="汇率"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="6"
                                        v-model.number="item.paymentConfig.exchangeRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label="渠道商手续费"
                                    label-width="10em"
                                >
                                    <div class="ma-b">
                                        <el-button @click="onAdd(item)">
                                            新增
                                        </el-button>
                                    </div>
                                    <el-table
                                        stripe
                                        border
                                        size="small"
                                        :data="item.paymentConfig.externalPoundageAmountList"
                                    >
                                        <el-table-column label="付款渠道">
                                            <template slot-scope="scope">
                                                <el-input
                                                    v-model="scope.row.paymentChannel"
                                                    :maxlength="20"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="手续费比例(%)">
                                            <template slot-scope="scope">
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="scope.row.poundageRate"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            label="操作"
                                            width="60"
                                        >
                                            <template slot-scope="scope">
                                                <el-button
                                                    size="small"
                                                    type="text"
                                                    @click="onDelete(scope.$index, item.paymentConfig.externalPoundageAmountList)"
                                                >
                                                    <span>删除</span>
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-form-item>
                            </el-col>
                            <el-col
                                :span="12"
                                v-if="item.id === 512"
                            >
                                <el-form-item
                                    prop="token"
                                    label="token"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.token"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="currency"
                                    label="币种"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.currency"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageRate"
                                    label="手续费(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageCostRate"
                                    label="手续费成本(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageCostRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="exchangeRate"
                                    label="汇率"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="6"
                                        v-model.number="item.paymentConfig.exchangeRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label="渠道商手续费"
                                    label-width="10em"
                                >
                                    <div class="ma-b">
                                        <el-button @click="onAdd(item)">
                                            新增
                                        </el-button>
                                    </div>
                                    <el-table
                                        stripe
                                        border
                                        size="small"
                                        :data="item.paymentConfig.externalPoundageAmountList"
                                    >
                                        <el-table-column label="付款渠道">
                                            <template slot-scope="scope">
                                                <el-input
                                                    v-model="scope.row.paymentChannel"
                                                    :maxlength="20"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="手续费比例(%)">
                                            <template slot-scope="scope">
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="scope.row.poundageRate"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            label="操作"
                                            width="60"
                                        >
                                            <template slot-scope="scope">
                                                <el-button
                                                    size="small"
                                                    type="text"
                                                    @click="onDelete(scope.$index, item.paymentConfig.externalPoundageAmountList)"
                                                >
                                                    <span>删除</span>
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-form-item>
                            </el-col>
                            <el-col
                                :span="12"
                                v-if="item.id === 4096"
                            >
                                <el-form-item
                                    prop="mpaccountAppid"
                                    label="小程序appid"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.mpaccountAppid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="wxaccountMchid"
                                    label="支付商户ID"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.mpaccountMchid"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="wxaccountMchkey"
                                    label="支付商户key"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.mpaccountMchkey"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageRate"
                                    label="手续费(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageCostRate"
                                    label="手续费成本(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageCostRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="exchangeRate"
                                    label="汇率"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="6"
                                        v-model.number="item.paymentConfig.exchangeRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label="渠道商手续费"
                                    label-width="10em"
                                >
                                    <div class="ma-b">
                                        <el-button @click="onAdd(item)">
                                            新增
                                        </el-button>
                                    </div>
                                    <el-table
                                        stripe
                                        border
                                        size="small"
                                        :data="item.paymentConfig.externalPoundageAmountList"
                                    >
                                        <el-table-column label="付款渠道">
                                            <template slot-scope="scope">
                                                <el-input
                                                    v-model="scope.row.paymentChannel"
                                                    :maxlength="20"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="手续费比例(%)">
                                            <template slot-scope="scope">
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="scope.row.poundageRate"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            label="操作"
                                            width="60"
                                        >
                                            <template slot-scope="scope">
                                                <el-button
                                                    size="small"
                                                    type="text"
                                                    @click="onDelete(scope.$index, item.paymentConfig.externalPoundageAmountList)"
                                                >
                                                    <span>删除</span>
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-form-item>
                            </el-col>
                            <el-col
                                :span="12"
                                v-if="item.id === 16384"
                            >
                                <el-form-item
                                    prop="merchantId"
                                    label="merchantId"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.merchantId"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="authenticationCode"
                                    label="authenticationCode"
                                    label-width="10em"
                                >
                                    <el-input
                                        v-model="item.paymentConfig.authenticationCode"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageRate"
                                    label="手续费(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="paymentPoundageCostRate"
                                    label="手续费成本(%)"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.paymentConfig.paymentPoundageCostRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="exchangeRate"
                                    label="汇率"
                                    label-width="10em"
                                >
                                    <el-input-number
                                        controls-position="right"
                                        :min="0"
                                        :precision="6"
                                        v-model.number="item.paymentConfig.exchangeRate"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label="渠道商手续费"
                                    label-width="10em"
                                >
                                    <div class="ma-b">
                                        <el-button @click="onAdd(item)">
                                            新增
                                        </el-button>
                                    </div>
                                    <el-table
                                        stripe
                                        border
                                        size="small"
                                        :data="item.paymentConfig.externalPoundageAmountList"
                                    >
                                        <el-table-column label="付款渠道">
                                            <template slot-scope="scope">
                                                <el-input
                                                    v-model="scope.row.paymentChannel"
                                                    :maxlength="20"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="手续费比例(%)">
                                            <template slot-scope="scope">
                                                <el-input-number
                                                    controls-position="right"
                                                    :min="0"
                                                    :precision="2"
                                                    v-model.number="scope.row.poundageRate"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            label="操作"
                                            width="60"
                                        >
                                            <template slot-scope="scope">
                                                <el-button
                                                    size="small"
                                                    type="text"
                                                    @click="onDelete(scope.$index, item.paymentConfig.externalPoundageAmountList)"
                                                >
                                                    <span>删除</span>
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-form-item>
                            </el-col>
                        </el-tab-pane>
                    </el-tabs>
                </el-form>
            </el-col>
            <div class="pa-t">
                <el-button
                    size="small"
                    type="primary"
                    @click="onConfirm()"
                >
                    保 存
                </el-button>
                <el-button
                    size="small"
                    @click="$router.back()"
                    v-show="shopId != null"
                >
                    返 回
                </el-button>
            </div>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ShopPaymentConfig',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            shopPaymentConfigList: [],
            shopId: '',
            tabName: '',
        };
    },
    methods: {
        onConfirm() {
            this.$api.Sp.ShopPaymentConfig.save({
                shopId: this.shopId,
                configJson: JSON.stringify(this.shopPaymentConfigList),
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
            });
        },
        initShopPaymentConfig() {
            this.$api.Sp.ShopPaymentConfig.data({ shopId: this.shopId }).then(json => {
                const res = json.data.data;
                res.forEach(item => {
                    if (!item.paymentConfig) {
                        item.paymentConfig = {
                            externalPoundageAmountList: [],
                        };
                    } else {
                        item.paymentConfig = JSON.parse(item.paymentConfig);
                        if (!item.paymentConfig.externalPoundageAmountList) {
                            item.paymentConfig.externalPoundageAmountList = [];
                        }
                    }
                });
                this.tabName = res[0].paymentTypeName;
                this.shopPaymentConfigList = res;
            });
        },
        onAdd(item) {
            item.paymentConfig.externalPoundageAmountList.push({
                paymentChannel: '',
                poundageRate: 0,
            });
        },
        onDelete(index, row) {
            row.splice(index, 1);
        },
    },
    created() {
        this.shopId = this.$route.params.id;
        this.initShopPaymentConfig();
    },
};
</script>

<style lang="scss">
</style>

<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <el-row>
            <el-col :span="20">
                <el-form
                    ref="addForm"
                    size="small"
                    :model="formModel"
                    :rules="formRules"
                >
                    <el-form-item
                        prop="orgId"
                        label="组织机构"
                        label-width="13em"
                    >
                        <el-select
                            v-model="formModel.orgId"
                            placeholder="请选择"
                            :disabled="formModel.id != null"
                        >
                            <el-option
                                v-for="item in orgList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        prop="shopName"
                        label="店铺名称"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.shopName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="shopLogo"
                        label="店铺Logo"
                        label-width="13em"
                    >
                        <upload
                            action="/rs/attachment/uploadShopLogo"
                            :limit="1"
                            v-model="formModel.shopLogo"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="loadingImg"
                        label="加载图标"
                        label-width="13em"
                    >
                        <upload
                            action="/rs/attachment/uploadShopLogo"
                            :limit="1"
                            v-model="formModel.loadingImg"
                            tips="只能上传jpg/png/gif文件"
                            accept="image/jpeg,image/gif,image/png"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="miniAppsImg"
                        label="店铺小程序图标"
                        label-width="13em"
                    >
                        <upload
                            action="/rs/attachment/uploadShopLogo"
                            :limit="1"
                            v-model="formModel.miniAppsImg"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="customerServiceImg"
                        label="客服图标"
                        label-width="13em"
                    >
                        <upload
                            action="/rs/attachment/uploadShopLogo"
                            :limit="1"
                            v-model="formModel.customerServiceImg"
                            tips="只能上传jpg/png/gif文件"
                            accept="image/jpeg,image/gif,image/png"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="wxaccountQrCodeImage"
                        label="公众号二维码"
                        label-width="13em"
                    >
                        <upload
                            action="/rs/attachment/uploadShopLogo"
                            :limit="1"
                            v-model="formModel.wxaccountQrCodeImage"
                            tips="只能上传jpg/png/gif文件"
                            accept="image/jpeg,image/gif,image/png"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="waybillTemplate"
                        label="面单模板"
                        label-width="13em"
                    >
                        <upload
                            action="/rs/attachment/uploadShopLogo"
                            :limit="1"
                            v-model="formModel.waybillTemplate"
                            tips="只能上传jpg/png/gif文件"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="themeColorMain"
                        label="皮肤主颜色"
                        label-width="13em"
                    >
                        <el-color-picker v-model="formModel.themeColorMain" />
                    </el-form-item>
                    <el-form-item
                        prop="themeColorSecond"
                        label="皮肤副颜色"
                        label-width="13em"
                    >
                        <el-color-picker v-model="formModel.themeColorSecond" />
                    </el-form-item>
                    <el-form-item
                        prop="currencySymbol"
                        label="币种符号"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.currencySymbol"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="currencyCode"
                        label="币种代码"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.currencyCode"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="amountFormat"
                        label="金额格式"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.amountFormat"
                            auto-complete="off"
                        />
                        默认+0.00
                    </el-form-item>
                    <el-form-item
                        prop="isQuickBuyerAddress"
                        label="智能识别地址"
                        label-width="13em"
                    >
                        <el-switch
                            v-model="formModel.isQuickBuyerAddress"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            active-value="1"
                            inactive-value="0"
                        />
                    </el-form-item>
                    <!--<el-form-item
prop="moduleConfigCart"
label="是否开启购物车"
label-width="13em"
>
<el-switch
v-model="formModel.moduleConfigCart"
active-color="#13ce66"
inactive-color="#ff4949"
active-value="1"
inactive-value="0"/>
</el-form-item>-->
                    <el-form-item
                        prop="isGoodsMultiStyle"
                        label="商品素材多风格"
                        label-width="13em"
                    >
                        <el-switch
                            v-model="formModel.isGoodsMultiStyle"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            :active-value="true"
                            :inactive-value="false"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="principalName"
                        label="联系人姓名"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.principalName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="principalPosition"
                        label="联系人职务"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.principalPosition"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="principalMobile"
                        label="联系人电话"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.principalMobile"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="principalEmail"
                        label="联系人邮箱"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.principalEmail"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="principalQq"
                        label="联系人QQ"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.principalQq"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="shopRegionId"
                        label="店铺地址"
                        label-width="13em"
                    >
                        <CascaderPicker
                            :api-class="selectApi"
                            v-model="formModel.shopRegionId"
                            :p-id="2"
                            :min-lv="2"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="shopAddress"
                        label="详细地址"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.shopAddress"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="shopDesc"
                        label="店铺描述"
                        label-width="13em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.shopDesc"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="freightMode"
                        label="运费模式"
                        label-width="13em"
                    >
                        <dictionaries-picker
                            type="freightMode"
                            v-model="formModel.freightMode"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isAllowMoreLevel"
                        label="二级分类"
                        label-width="13em"
                    >
                        <el-switch
                            v-model="formModel.isAllowMoreLevel"
                        />
                        开启二级分类(备注：商品展示时默认只显示一级分类，如需显示二级分类则勾选此项）
                    </el-form-item>
                    <el-form-item
                        prop="isAllowOrder"
                        label="是否允许下单"
                        label-width="13em"
                    >
                        <el-switch
                            v-model="formModel.isAllowOrder"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isShowNotice"
                        label="是否显示公告"
                        label-width="13em"
                    >
                        <el-switch
                            v-model="formModel.isShowNotice"
                        />
                    </el-form-item>
                    <el-form-item
                        key="shopNoticeImage"
                        prop="shopNoticeImage"
                        label="公告图片"
                        label-width="13em"
                        v-if="formModel.isShowNotice"
                    >
                        <upload
                            action="/rs/attachment/uploadShopLogo"
                            :limit="1"
                            v-model="formModel.shopNoticeImage"
                        />
                    </el-form-item>
                    <el-form-item
                        key="shopNoticeLink"
                        prop="shopNoticeLink"
                        label="公告链接"
                        label-width="13em"
                        v-if="formModel.isShowNotice"
                    >
                        <el-input
                            v-model="formModel.shopNoticeLink"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="shopDomain"
                        label="店铺域名"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.shopDomain"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="shopLevelId"
                        label="店铺等级"
                        label-width="13em"
                    >
                        <el-select
                            v-model="formModel.shopLevelId"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in shopLevelList"
                                :key="item.id"
                                :label="item.levelName"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        prop="shopStatus"
                        label="商户状态"
                        label-width="13em"
                    >
                        <dictionaries-picker
                            type="shopStatus"
                            v-model="formModel.shopStatus"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="joinTime"
                        label="入驻时间"
                        label-width="13em"
                    >
                        <el-date-picker
                            v-model="formModel.joinTime"
                            type="datetime"
                            placeholder="选择入驻时间"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="memberRegisterMode"
                        label="会员注册模式"
                        label-width="13em"
                    >
                        <dictionaries-picker
                            type="memberRegisterMode"
                            v-model="formModel.memberRegisterMode"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="memberAccountSign"
                        label="会员账号标识"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.memberAccountSign"
                            auto-complete="off"
                        />
                        (4个字母以内)
                    </el-form-item>
                    <el-form-item
                        prop="buyerConfig"
                        label="会员功能"
                        label-width="13em"
                    >
                        <checkbox-group-andOr
                            :data="[
                                {id:1,name:'分销等级成长值'},
                            ]"
                            v-model="formModel.buyerConfig"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="distributorRegisterMode"
                        label="分销商注册模式"
                        label-width="13em"
                    >
                        <dictionaries-picker
                            type="distributorRegisterMode"
                            v-model="formModel.distributorRegisterMode"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isMustInvitationCode"
                        label="推荐码是否必填"
                        label-width="13em"
                    >
                        <template>
                            <el-radio
                                v-model="formModel.isMustInvitationCode"
                                :label="true"
                            >
                                是
                            </el-radio>
                            <el-radio
                                v-model="formModel.isMustInvitationCode"
                                :label="false"
                            >
                                否
                            </el-radio>
                        </template>
                    </el-form-item>
                    <el-form-item
                        prop="distributorQrcodeExpire"
                        label="分销商二维码有效期(天)"
                        label-width="13em"
                    >
                        <template>
                            <el-input-number
                                controls-position="right"
                                :min="0"
                                v-model.number="formModel.distributorQrcodeExpire"
                            />
                        </template>
                    </el-form-item>
                    <el-form-item
                        prop="isDistributorQrcodeUnique"
                        label="分销商二维码是否唯一"
                        label-width="13em"
                    >
                        <template>
                            <el-radio
                                v-model="formModel.isDistributorQrcodeUnique"
                                :label="true"
                            >
                                是
                            </el-radio>
                            <el-radio
                                v-model="formModel.isDistributorQrcodeUnique"
                                :label="false"
                            >
                                否
                            </el-radio>
                        </template>
                    </el-form-item>
                    <el-form-item
                        prop="distributionIncomeMode"
                        label="分销收益模式"
                        label-width="13em"
                    >
                        <checkbox-group-andOr
                            type="distributionIncomeMode"
                            v-model="formModel.distributionIncomeMode"
                        />
                    </el-form-item>
                    <el-form-item
                        key="distributionLevel"
                        prop="distributionLevel"
                        label="提成层级"
                        label-width="13em"
                        v-if="eachlevelincome"
                    >
                        <dictionaries-picker
                            type="distributionLevel"
                            v-model="formModel.distributionLevel"
                        />
                    </el-form-item>
                    <el-form-item
                        key="shopStoreType"
                        prop="shopStoreType"
                        label="门店类型"
                        label-width="13em"
                    >
                        <dictionaries-picker
                            type="shopStoreType"
                            v-model="formModel.shopStoreType"
                        />
                    </el-form-item>
                    <el-form-item
                        key="storeOpenMode"
                        prop="storeOpenMode"
                        label="门店开通模式"
                        label-width="13em"
                        v-if="formModel.shopStoreType != 99"
                    >
                        <dictionaries-picker
                            type="storeOpenMode"
                            v-model="formModel.storeOpenMode"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="sellGoodsType"
                        label="售卖商品类型"
                        label-width="13em"
                    >
                        <checkbox-group-andOr
                            type="goodsType"
                            v-model="formModel.sellGoodsType"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="goodsSendMode"
                        label="商品发货方式"
                        label-width="13em"
                    >
                        <el-checkbox-group v-model="formModel.goodsSendMode">
                            <el-checkbox label="1">
                                国内现货
                            </el-checkbox>
                            <el-checkbox label="2">
                                个人行邮
                            </el-checkbox>
                            <el-checkbox label="3">
                                BC直邮
                            </el-checkbox>
                            <el-checkbox label="4">
                                保税区发货
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item
                        prop="shipMethod"
                        label="配送方式"
                        label-width="13em"
                    >
                        <checkbox-group-andOr
                            type="shipMethod"
                            v-model="formModel.shipMethod"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="goodsConfig"
                        label="商品功能"
                        label-width="13em"
                    >
                        <checkbox-group-andOr
                            :data="[
                                {id:1,name:'商品保险'},
                                {id:2,name:'商品预售'},
                            ]"
                            v-model="formModel.goodsConfig"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="paymentMode"
                        label="订单付款模式"
                        label-width="13em"
                    >
                        <checkbox-group-andOr
                            type="paymentMode"
                            v-model="formModel.paymentMode"
                        />
                    </el-form-item>
                    <el-form-item
                        key="onlinePaymentType"
                        prop="onlinePaymentType"
                        label="订单付款类型"
                        label-width="13em"
                        v-if="onlinePayment"
                    >
                        <checkbox-group-andOr
                            :data="[
                                {id:2,name:'微信支付'},
                                {id:4,name:'银联支付'},
                                {id:8,name:'通联支付'},
                                {id:16,name:'alphapay'},
                                {id:32,name:'支付宝支付'},
                                {id:64,name:'Stripe'},
                                {id:128,name:'中投科信'},
                                {id:256,name:'hantePay'},
                                {id:512,name:'citconPay'},
                                {id:1024,name:'零钱支付'},
                                {id:4096,name:'小程序支付'},
                                {id:8192,name:'账期支付'},
                                {id:16384,name:'supayTech支付'},
                            ]"
                            v-model="formModel.onlinePaymentType"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="prepaidPaymentMode"
                        label="充值付款模式"
                        label-width="13em"
                    >
                        <checkbox-group-andOr
                            type="paymentMode"
                            v-model="formModel.prepaidPaymentMode"
                        />
                    </el-form-item>
                    <el-form-item
                        key="prepaidPaymentType"
                        prop="prepaidPaymentType"
                        label="充值付款类型"
                        label-width="13em"
                        v-if="onlinePaymentBalance"
                    >
                        <checkbox-group-andOr
                            :data="[
                                {id:2,name:'微信支付'},
                                {id:4,name:'银联支付'},
                                {id:8,name:'通联支付'},
                                {id:16,name:'alphapay'},
                                {id:32,name:'支付宝支付'},
                                {id:64,name:'Stripe'},
                                {id:128,name:'中投科信'},
                                {id:256,name:'hantePay'},
                                {id:512,name:'citconPay'},
                                {id:4096,name:'小程序支付'},
                                {id:16384,name:'supayTech支付'},
                            ]"
                            v-model="formModel.prepaidPaymentType"
                        />
                    </el-form-item>
                    <el-form-item
                        key="storePaymentType"
                        prop="storePaymentType"
                        label="门店付款类型"
                        label-width="13em"
                    >
                        <checkbox-group-andOr
                            :data="[
                                {id:2,name:'微信支付'},
                                {id:4,name:'银联支付'},
                                {id:8,name:'通联支付'},
                                {id:16,name:'alphapay'},
                                {id:32,name:'支付宝支付'},
                                {id:64,name:'Stripe'},
                                {id:128,name:'中投科信'},
                                {id:256,name:'hantePay'},
                                {id:512,name:'citconPay'},
                                {id:2048,name:'现金支付'},
                                {id:4096,name:'小程序支付'},
                                {id:16384,name:'supayTech支付'},
                            ]"
                            v-model="formModel.storePaymentType"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="orderNoticeMobile"
                        label="下单提醒"
                        label-width="13em"
                    >
                        <checkbox-group-andOr
                            type="orderNoticeMode"
                            v-model="formModel.orderNoticeMode"
                        />
                    </el-form-item>
                    <el-form-item
                        key="orderNoticeMobile"
                        prop="orderNoticeMobile"
                        label="提醒手机号"
                        label-width="13em"
                        v-if="orderNoticeMobile"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.orderNoticeMobile"
                            auto-complete="off"
                        />
                        多个手机号用|分隔
                    </el-form-item>
                    <el-form-item
                        prop="mapPlaceKey"
                        label="地图接口key"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.mapPlaceKey"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="meiQiaEntId"
                        label="美洽企业ID"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.meiQiaEntId"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isPushOrderToGateway"
                        label="订单是否推送网关"
                        label-width="13em"
                    >
                        <el-switch
                            v-model="formModel.isPushOrderToGateway"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="gatewayAppKey"
                        label="gatewayAppKey"
                        label-width="13em"
                        v-if="formModel.isPushOrderToGateway"
                    >
                        <el-input
                            v-model="formModel.gatewayAppKey"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="gatewayAppSecret"
                        label="gatewayAppSecret"
                        label-width="13em"
                        v-if="formModel.isPushOrderToGateway"
                    >
                        <el-input
                            v-model="formModel.gatewayAppSecret"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="gatewayPlatformName"
                        label="gatewayPlatformName"
                        label-width="13em"
                        v-if="formModel.isPushOrderToGateway"
                    >
                        <el-input
                            v-model="formModel.gatewayPlatformName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="gatewayPlatformCode"
                        label="gatewayPlatformCode"
                        label-width="13em"
                        v-if="formModel.isPushOrderToGateway"
                    >
                        <el-input
                            v-model="formModel.gatewayPlatformCode"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="gatewayDistributorName"
                        label="gatewayDistributorName"
                        label-width="13em"
                        v-if="formModel.isPushOrderToGateway"
                    >
                        <el-input
                            v-model="formModel.gatewayDistributorName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="gatewayEbpCode"
                        label="gatewayEbpCode"
                        label-width="13em"
                        v-if="formModel.isPushOrderToGateway"
                    >
                        <el-input
                            v-model="formModel.gatewayEbpCode"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="gatewayRecpAccount"
                        label="gatewayRecpAccount"
                        label-width="13em"
                        v-if="formModel.isPushOrderToGateway"
                    >
                        <el-input
                            v-model="formModel.gatewayRecpAccount"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="gatewayRecpCode"
                        label="gatewayRecpCode"
                        label-width="13em"
                        v-if="formModel.isPushOrderToGateway"
                    >
                        <el-input
                            v-model="formModel.gatewayRecpCode"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="gatewayRecpName"
                        label="gatewayRecpName"
                        label-width="13em"
                        v-if="formModel.isPushOrderToGateway"
                    >
                        <el-input
                            v-model="formModel.gatewayRecpName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isPushOrderErp"
                        label="订单是否推送erp"
                        label-width="13em"
                    >
                        <el-switch
                            v-model="formModel.isPushOrderErp"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="erpAppKey"
                        label="erpAppKey"
                        label-width="13em"
                        v-if="formModel.isPushOrderErp"
                    >
                        <el-input
                            v-model="formModel.erpAppKey"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="erpAppSecret"
                        label="erpAppSecret"
                        label-width="13em"
                        v-if="formModel.isPushOrderErp"
                    >
                        <el-input
                            v-model="formModel.erpAppSecret"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="qskdAppKey"
                        label="签收快递AppKey"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.qskdAppKey"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="qskdAppSecret"
                        label="签收快递AppSecret"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.qskdAppSecret"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isShowRMBAmount"
                        label="是否显示人民币"
                        label-width="13em"
                    >
                        <el-switch
                            v-model="formModel.isShowRMBAmount"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="RMBAmountRate"
                        label="人民币汇率"
                        label-width="13em"
                        v-if="formModel.isShowRMBAmount"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="4"
                            v-model.number="formModel.RMBAmountRate"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="sendConsignor"
                        label="发件人"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.sendConsignor"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="sendTel"
                        label="发件人电话"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.sendTel"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isStoreEditSendInfo"
                        label="门店是否维护发件人信息"
                        label-width="13em"
                    >
                        <el-switch
                            v-model="formModel.isStoreEditSendInfo"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isOrderEditSendInfo"
                        label="下单修改发件人信息"
                        label-width="13em"
                    >
                        <el-switch
                            v-model="formModel.isOrderEditSendInfo"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="remarks"
                        label="备注"
                        label-width="13em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.remarks"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="minWithdrawAmount"
                        label="最低提现金额"
                        label-width="13em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="2"
                            v-model.number="formModel.minWithdrawAmount"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="withdrawPoundageRate"
                        label="提现手续费（%）"
                        label-width="13em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :max="100"
                            :precision="2"
                            v-model.number="formModel.withdrawPoundageRate"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="marketCodeContacts"
                        label="一物一码联系人"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.marketCodeContacts"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="marketCodeCouponId"
                        label="一物一码优惠券ID"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.marketCodeCouponId"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="marketCodeWarnNum"
                        label="一物一码最大查询次数"
                        label-width="13em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="1"
                            :precision="0"
                            v-model.number="formModel.marketCodeWarnNum"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isGiftCardReturnPurse"
                        label="礼品卡是否返还零钱"
                        label-width="13em"
                    >
                        <el-switch
                            v-model="formModel.isGiftCardReturnPurse"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isRegisterIHealth"
                        label="是否注册iHealth"
                        label-width="13em"
                    >
                        <el-switch
                            v-model="formModel.isRegisterIHealth"
                        />
                    </el-form-item>
                    <el-form-item
                        label-width="13em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ShopEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                orgId: '',
                shopName: '',
                shopLogo: [],
                principalName: '',
                principalPosition: '',
                principalMobile: '',
                principalEmail: '',
                principalQq: '',
                shopRegionId: '',
                shopAddress: '',
                shopDesc: '',
                freightMode: '',
                isAllowMoreLevel: false,
                shopDomain: '',
                shopLevelId: '',
                shopStatus: '',
                memberRegisterMode: '',
                memberAccountSign: '',
                mapPlaceKey: '',
                publicApikey: '',
                publicSecret: '',
                remarks: '',
                distributionIncomeMode: '',
                distributorRegisterMode: 1,
                isMustInvitationCode: false,
                distributionLevel: '',
                sellGoodsType: '',
                shipMethod: '',
                paymentMode: '',
                onlinePaymentType: '',
                prepaidPaymentType: '',
                storePaymentType: '',
                orderNoticeMode: '',
                orderNoticeMobile: '',
                orderNoticeWebchat: '',
                isAllowOrder: '',
                isShowNotice: '',
                shopNoticeImage: [],
                shopNoticeLink: '',
                loadingImg: [],
                miniAppsImg: [],
                customerServiceImg: [],
                wxaccountQrCodeImage: [],
                waybillTemplate: [],
                currencySymbol: '',
                currencyCode: '',
                themeColorMain: '',
                themeColorSecond: '',
                isQuickBuyerAddress: 0,
                moduleConfigCart: 0,
                isGoodsMultiStyle: false,
                shopStoreType: '',
                meiQiaEntId: '',
                isPushOrderErp: false,
                erpAppKey: '',
                erpAppSecret: '',
                isPushOrderToGateway: false,
                gatewayAppKey: '',
                gatewayAppSecret: '',
                gatewayPlatformName: '',
                gatewayPlatformCode: '',
                gatewayDistributorName: '',
                gatewayEbpCode: '',
                gatewayRecpAccount: '',
                gatewayRecpCode: '',
                gatewayRecpName: '',
                isShowRMBAmount: false,
                RMBAmountRate: '',
                sendConsignor: '',
                sendTel: '',
                isStoreEditSendInfo: '',
                storeOpenMode: '',
                qskdAppKey: '',
                qskdAppSecret: '',
                amountFormat: '',
                prepaidPaymentMode: '',
                distributorQrcodeExpire: '7',
                isDistributorQrcodeUnique: false,
                isOrderEditSendInfo: false,
                minWithdrawAmount: '',
                withdrawPoundageRate: '',
                goodsSendMode: [],
                goodsConfig: '',
                buyerConfig: '',
                marketCodeContacts: '',
                marketCodeCouponId: '',
                marketCodeWarnNum: '',
                isGiftCardReturnPurse: false,
                isRegisterIHealth: false,
            },
            // 表单校验规则
            formRules: {
                orgId: {
                    required: true,
                    message: '请选择组织机构',
                    trigger: 'blur',
                },
                shopName: {
                    required: true,
                    message: '请输入店铺名称',
                    trigger: 'blur',
                },
                shopLogo: {
                    type: 'array',
                    required: true,
                    message: '请上传等级图片',
                },
                miniAppsImg: {
                    type: 'array',
                    required: true,
                    message: '请上传小程序图片',
                },
                customerServiceImg: {
                    type: 'array',
                    required: true,
                    message: '请上传客服图片',
                },
                waybillTemplate: {
                    type: 'array',
                    required: true,
                    message: '请上传面单模板',
                },
                principalName: {
                    required: true,
                    message: '请输入联系人姓名',
                    trigger: 'blur',
                },
                principalMobile: {
                    required: true,
                    message: '请输入联系人电话',
                    trigger: 'blur',
                },
                shopRegionId: {
                    required: true,
                    message: '请选择店铺地址',
                    trigger: 'blur',
                },
                shopAddress: {
                    required: true,
                    message: '请输入店铺详细地址',
                    trigger: 'blur',
                },
                freightMode: {
                    required: true,
                    message: '请选择运费模式',
                    trigger: 'blur',
                },
                isAllowMoreLevel: {
                    required: true,
                    message: '请选择是否开启二级节点',
                    trigger: 'blur',
                },
                sellGoodsType: [
                    {
                        required: true,
                        message: '请选择售卖商品类型',
                    },
                    {
                        type: 'integer',
                        min: 1,
                        message: '请选择售卖商品类型',
                    },
                ],
                shopDomain: {
                    required: true,
                    message: '请输入店铺域名',
                },
                shopLevelId: {
                    required: true,
                    message: '请选择店铺等级',
                },
                shopStatus: {
                    required: true,
                    message: '请选择商户状态',
                },
                memberRegisterMode: {
                    required: true,
                    message: '请选择会员注册模式',
                },
                isMustInvitationCode: {
                    required: true,
                    message: '请选择推荐码是否必填',
                },
                distributorRegisterMode: {
                    required: true,
                    message: '请选择分销注册模式',
                },
                memberAccountSign: {
                    required: true,
                    message: '请输入会员账号标识',
                },
                distributionLevel: {
                    required: true,
                    message: '请选择提成层级',
                },
                shipMethod: [
                    {
                        required: true,
                        message: '请选择配送方式',
                    },
                    {
                        type: 'integer',
                        min: 1,
                        message: '请选择配送方式',
                    },
                ],
                paymentMode: [
                    {
                        required: true,
                        message: '请选择付款模式',
                    },
                    {
                        type: 'integer',
                        min: 1,
                        message: '请选择付款模式',
                    },
                ],
                onlinePaymentType: [
                    {
                        required: true,
                        message: '请选择线上付款模式',
                    },
                    {
                        type: 'integer',
                        min: 1,
                        message: '请选择线上付款模式',
                    },
                ],
                shopNoticeImage: {
                    type: 'array',
                    required: true,
                    message: '请上传公告图片',
                },
                goodsSendMode: {
                    type: 'array',
                    required: true,
                    message: '请选择送货方式',
                },
                currencySymbol: {
                    required: true,
                    message: '请输入币种符号',
                    trigger: 'blur',
                },
                currencyCode: {
                    required: true,
                    message: '请输入币种代码',
                    trigger: 'blur',
                },
                themeColorMain: {
                    required: true,
                    message: '请选择皮肤主颜色',
                    trigger: 'blur',
                },
                themeColorSecond: {
                    required: true,
                    message: '请选择皮肤副颜色',
                    trigger: 'blur',
                },
                isQuickBuyerAddress: {
                    required: true,
                    message: '请选择',
                    trigger: 'blur',
                },
                moduleConfigCart: {
                    required: true,
                    message: '请选择',
                    trigger: 'blur',
                },
                isGoodsMultiStyle: {
                    required: true,
                    message: '请选择',
                    trigger: 'blur',
                },
                storeOpenMode: {
                    required: true,
                    message: '请选择',
                },
                shopStoreType: {
                    required: true,
                    message: '请选择',
                },
                isDistributorQrcodeUnique: {
                    required: true,
                    message: '请选择',
                },
                isOrderEditSendInfo: {
                    required: true,
                    message: '请选择',
                },
                distributorQrcodeExpire: {
                    required: true,
                    message: '请输入分销商二维码有效期',
                },
            },
            selectApi: this.$api.Rs.Region,
            shopLevelList: [],
            // 组织机构列表
            orgList: [],
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Sp.Shop.save({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        getShopLevelList() {
            this.$api.Sp.ShopLevel.select().then(res => {
                this.shopLevelList = res.data.data;
            });
        },
        initOrgList() {
            this.$api.Rs.Org.select().then(json => {
                const res = json.data.data;
                this.orgList = res;
            });
        },
        initShop() {
            this.$api.Sp.Shop.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.Common.formModelMerge(this.formModel, res);
            });
        },
    },
    computed: {
        eachlevelincome() {
            return (this.formModel.distributionIncomeMode & 4) !== 0;
        },
        onlinePayment() {
            return (this.formModel.paymentMode & 1) !== 0;
        },
        onlinePaymentBalance() {
            return (this.formModel.prepaidPaymentMode & 1) !== 0;
        },
        orderNoticeMobile() {
            return (this.formModel.orderNoticeMode & 1) !== 0;
        },
    },
    created() {
        this.getShopLevelList();
        this.initOrgList();
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.initShop();
        }
    },
};
</script>

<style lang="scss">
</style>
